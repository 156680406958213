import { AppBar, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledWrapperContent = styled(Box)<{ headerAppHeight: number }>(({ headerAppHeight }) => ({
  height: `calc(100vh - ${headerAppHeight + 1}px)`,
  display: 'flex',
  justifyContent: 'center',
}));

export const StyledAppBar = styled(AppBar)<{ headerAppHeight: number }>(({ theme, headerAppHeight }) => ({
  height: `${headerAppHeight}px`,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  boxShadow: 'none',
  zIndex: 1500,
}));

export const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
  minWidth: '320px',
});

export const StyledContainer = styled(Container)({
  margin: 0,
});

export const StyledScrollableContainer = styled(Container)({
  overflowX: 'auto',
});
