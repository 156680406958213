import { TextField, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledInputTextField = styled(TextField)<TextFieldProps & { isLargerScreen?: boolean }>(
  ({ isLargerScreen }) => ({
    width: isLargerScreen ? '220px' : '100%',
  }),
);

export const StyledCampaignsText = styled(Typography)<TypographyProps & { theme?: Theme }>(({ theme }) => ({
  fontWeight: 500,
  color: theme.colors.primary.dark,
}));

export const StyledTypographyWrap = styled(Typography)<TypographyProps>({
  textWrap: 'wrap',
});
