import { MenuItem, MenuItemProps, TextField, TextFieldProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledTextField = styled(TextField)<TextFieldProps & { theme?: Theme }>(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.colors.white,
  },
  appearance: 'none',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

export const StyledMenuItemSelectableText = styled(MenuItem)<MenuItemProps>({
  cursor: 'text',
  pointerEvents: 'stroke',
  userSelect: 'text',
  '-webkit-user-select': 'text',
  '-moz-user-select': 'text',
});
