import { Chip, ChipProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledProfileChipContainer = styled(Chip)<ChipProps & { theme?: Theme; isLabelVisible?: boolean }>(
  ({ theme, isLabelVisible }) => ({
    '&.MuiButtonBase-root': {
      height: '42px',
      maxWidth: '224px',
      borderRadius: '25px',
    },
    '&.MuiButtonBase-root .MuiAvatar-root': {
      height: '32px',
      width: '32px',
      color: theme.colors.white,
      fontSize: '20px',
    },
    '&.MuiButtonBase-root .MuiChip-label': {
      fontSize: '14px',
      fontWeight: 600,
      paddingLeft: isLabelVisible ? '10px' : '5px',
      paddingRight: isLabelVisible ? '10px' : '5px',
    },
  }),
);
