import { PERIOD as period } from '@constants/billing';

import { Box, BoxProps, Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

import { PeriodValue } from '@/shared/types';

export const StyledBox = styled(Box)<BoxProps & { active: PeriodValue; theme?: Theme; isMobile?: boolean }>(
  // @ts-ignore
  ({ theme, active, isMobile }) => ({
    backgroundColor: theme.colors.secondary.main,
    position: 'absolute',
    top: '3px',
    borderRadius: '96px',
    transition: 'left 0.3s ease-in-out, right 0.3s ease-in-out',
    boxShadow: 3,
    zIndex: 1,
    left: active === period.month ? '3px' : isMobile ? '175px' : '225px',
    right: active === period.year ? '3px' : 'auto',
  }),
);

export const StyledStackContainer = styled(Stack)<StackProps & { theme?: Theme }>(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.colors.secondary.main,
  borderRadius: '96px',
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
}));

export const StyledStack = styled(Stack)<StackProps>(() => ({
  zIndex: 2,
  transition: 'opacity 0.3s ease-in-out',
}));

export const StyledTypography = styled(Typography)<TypographyProps & { isActive: boolean }>(({ isActive }) => ({
  color: isActive ? 'white' : 'black',
}));
