import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginatedLeadsDto } from '@api/api';
import { useDynamicColumns } from '@hooks/common/useDynamicColumns/useDynamicColumns';
import NoLeadsAvailableScreen from '@pages/CampaignDetails/components/Leads/components/NoLeadsAvailableScreen';
import { Page } from '@pages/LeadScrapers/types';

import { alpha, LinearProgress, SxProps, useTheme } from '@mui/material';
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid';

import { StyledDataGrid } from './styled';

const ScrapekingDbDynamicDataGrid: FC<{
  handlePageChange: (newPaginationModel: GridPaginationModel) => void;
  paginationModel: Page;
  isDataLoading: boolean;
  data?: PaginatedLeadsDto;
  isReadOnly?: boolean;
  sx?: SxProps;
  expandedFilters?: boolean;
  campaignType?: string;
  handleCheckboxSelection: (selectionModel: GridRowSelectionModel) => void;
  selectedLeadIds?: GridRowSelectionModel;
}> = ({
  data,
  handlePageChange,
  paginationModel,
  isDataLoading,
  sx,
  campaignType,
  expandedFilters,
  handleCheckboxSelection,
  selectedLeadIds,
}) => {
  const { dbColumns, onRowClick } = useDynamicColumns({ campaignType });
  const { t } = useTranslation();
  const theme = useTheme();

  const props = useMemo(
    () => ({
      rowCount: data?.total,
      loading: isDataLoading,
      pagination: !!data?.total || true,
      rows: data?.data || [],
      onRowClick,
      columns: dbColumns,
    }),
    [data?.total, data?.data, isDataLoading, onRowClick, dbColumns],
  );

  return (
    <StyledDataGrid
      checkboxSelection
      disableColumnFilter
      disableRowSelectionOnClick
      disableColumnSelector
      disableColumnMenu
      paginationMode="server"
      showCellVerticalBorder={false}
      pageSizeOptions={[25, 50, 100]}
      onPaginationModelChange={handlePageChange}
      paginationModel={paginationModel}
      slots={{
        loadingOverlay: LinearProgress,
        noRowsOverlay: NoLeadsAvailableScreen,
      }}
      slotProps={{
        pagination: {
          labelRowsPerPage: t('common.paginationPerPageText'),
        },
      }}
      sx={{
        ...sx,
        '& .MuiDataGrid-row.purchased': {
          backgroundColor: alpha(theme.colors.primary.light as string, 0.2),
        },
      }}
      getRowId={row => row.leads_id}
      expandedFilters={expandedFilters}
      onRowSelectionModelChange={handleCheckboxSelection}
      rowSelectionModel={selectedLeadIds}
      getRowClassName={params => (params.row.purchased ? 'purchased' : '')}
      {...props}
    />
  );
};

export default memo(ScrapekingDbDynamicDataGrid);
