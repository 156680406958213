/* eslint-disable no-param-reassign */
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckoutCreateDto,
  TopupDto,
  useCancelSubscriptionMutation,
  useCreateCheckoutMutation,
  useCreateTopupMutation,
  useGetMeQuery,
  useGetPackagesQuery,
  useGetPricesQuery,
  useGetSubscriptionQuery,
} from '@api/api';
import { ERROR, SUCCESS } from '@constants/auth';
import { PERIOD } from '@constants/billing';
import {
  API_ERROR_MSG_PATH,
  BILLING_FREE_TOKENS_AMOUNT,
  BILLING_PRO_TOKENS_AMOUNT,
  CONTACT_US_EMAIL,
} from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import { GetPackagesResponse, GetPricesResponse, GetSubscriptionsResponse, SortedPackage } from '@pages/Settings/types';
import { convertFromCents, isTrialExpired } from '@pages/Settings/utils';
import { formatCurrency } from '@utils/formatCurrency';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import { amber, blue, deepPurple, grey } from '@mui/material/colors';
import { BillingConfirmModalId } from '@components/BillingConfirmModal';

import { ConfirmModalId } from '@/shared/ConfirmModal/ConfirmModal';
import { PeriodValue } from '@/shared/types';

const colors = [amber[50], deepPurple[50], grey[200]];

const commonPayload = {
  successUrl: window.location.origin,
  cancelUrl: window.location.origin,
};

export interface PackageConfig {
  id: number;
  isActive: boolean;
  bgColor: string;
  title: string;
  subtitle: string;
  packagePriceTitle: string;
  isFree?: boolean;
  tokensInPackage: number;
  onClick: () => void;
  onCancelClick: (e: MouseEvent) => void;
  isExpired?: boolean;
  isLoading?: boolean;
  isPopular?: boolean;
  packageButtonLocale?: string;
  isDisabledPlanSwitch?: boolean;
}

export const useBilling = (period: PeriodValue = PERIOD.month) => {
  const [activePrice, setActivePrice] = useState(0);
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const { data: packagesData, isLoading: isLoadingPackages } = useGetPackagesQuery(undefined, { refetchOnFocus: true });
  const { data: pricesData, isLoading: isLoadingPrices } = useGetPricesQuery(undefined, { refetchOnFocus: true });
  const { data: me, isLoading: isLoadingUser } = useGetMeQuery(undefined, { refetchOnFocus: true });
  const {
    data: subscriptionData,
    isLoading: isLoadingSubs,
    error: subscriptionError,
  } = useGetSubscriptionQuery(undefined, { refetchOnFocus: true });

  const [subscribeMutation, { isLoading: isLoadingSubscription }] = useCreateCheckoutMutation();
  const [topupMutation, { isLoading: isLoadingTopupSubscription }] = useCreateTopupMutation();
  const [cancelSubscriptionMutation, { isLoading: isLoadingCancelSubscription }] = useCancelSubscriptionMutation();

  const handleCheckout = useCallback(
    async (priceId: string) => {
      try {
        const payload: CheckoutCreateDto | TopupDto = {
          ...commonPayload,
          priceId,
        };
        const response = await subscribeMutation({ checkoutCreateDto: payload }).unwrap();

        window.location.href = response.url;
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    },
    [snackbar, subscribeMutation],
  );

  const handleTopup = useCallback(
    async (priceId: string) => {
      try {
        await topupMutation({ topupDto: { ...commonPayload, priceId } }).unwrap();
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    },
    [snackbar, topupMutation],
  );

  const handleCancelSubscription = useCallback(async () => {
    try {
      await cancelSubscriptionMutation();
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  }, [cancelSubscriptionMutation, snackbar]);

  const convertedPrices = useMemo(
    () =>
      (pricesData as GetPricesResponse)?.data
        .map(item => ({
          ...item,
          unit_amount: formatCurrency(
            convertFromCents(period === PERIOD.month ? item.unit_amount : item.unit_amount / 12),
          ),
        }))
        .filter(price => price.recurring.interval === period) || [],
    [period, pricesData],
  );

  const isLoadingMutation = [isLoadingSubscription, isLoadingTopupSubscription, isLoadingCancelSubscription].some(
    Boolean,
  );
  const isLoadingQueries = [isLoadingPackages, isLoadingPrices, isLoadingUser, isLoadingSubs].some(Boolean);

  const config: PackageConfig[] = useMemo(() => {
    const freePackage = {
      id: 1,
      isActive: !me?.subscriptions?.[0]?.subscription_id,
      bgColor: blue[50],
      title: 'billing.packageTitle.free',
      subtitle: 'billing.packageText.free',
      packagePriceTitle: 'billing.price.free',
      isFree: true,
      tokensInPackage: BILLING_FREE_TOKENS_AMOUNT,
      onClick: () => {},
      onCancelClick: () => {},
      isExpired: isTrialExpired(me?.subscriptions?.[0]?.created_at as string),
    };

    const packageConfig =
      (packagesData as GetPackagesResponse)?.data.map(pkg => {
        const price = convertedPrices.find(priceItem => priceItem.id === pkg.default_price);
        const tokensInPackage = Number(pkg.metadata.tokens);

        const isActive =
          ((subscriptionData as GetSubscriptionsResponse)?.plan.id === pkg.default_price && !subscriptionError) ||
          ((subscriptionData as GetSubscriptionsResponse)?.metadata.original_id === pkg.default_price &&
            !subscriptionError);

        if (isActive && price) {
          setActivePrice(+price.unit_amount_decimal);
        }

        const isFromActiveAnnualToMonthlySubscription =
          price?.recurring.interval === PERIOD.month &&
          (subscriptionData as GetSubscriptionsResponse)?.plan.interval === PERIOD.year;

        const isDowngradeAnnualSubscription =
          price?.recurring.interval === PERIOD.year &&
          period === PERIOD.year &&
          !isActive &&
          +price.unit_amount_decimal < activePrice;

        return {
          id: +pkg.id,
          isActive,
          bgColor: grey[200],
          title: pkg.name,
          subtitle: pkg.description,
          packagePriceTitle: price ? `${price.unit_amount}/month` : '',
          tokensInPackage,
          onClick: async () => {
            if (price) {
              if (me?.subscriptions?.[0]?.stripe_customer_id) {
                if (isFromActiveAnnualToMonthlySubscription || isDowngradeAnnualSubscription) {
                  return NiceModal.show(ConfirmModalId, {
                    title: t('billing.pricingModalTitle'),
                    subtitle: t('billing.pricingModalSubtitle', { email: CONTACT_US_EMAIL }),
                    singleButton: true,
                  });
                }
              }
              if (isActive) {
                const result = await NiceModal.show(BillingConfirmModalId, {
                  price: +price.unit_amount_decimal,
                  tokens: tokensInPackage,
                  description: pkg.description
                    .split(';')
                    .map(desc => desc.trim())
                    .filter(desc => desc),
                  period,
                });
                if (result) {
                  await handleTopup(String(price.id));
                  snackbar.enqueueSnackbar(t('dashboard.tokens.topup', { tokens: tokensInPackage }), {
                    variant: SUCCESS,
                  });
                  return;
                }
              }
              handleCheckout(price.id);
            }
          },
          onCancelClick: async (e: MouseEvent) => {
            e.stopPropagation();
            const result = await NiceModal.show(ConfirmModalId, {
              title: 'billing.other.cancelSubscriptionModalText',
            });
            if (result) handleCancelSubscription();
          },
          isLoading: isLoadingMutation,
          isPopular: +pkg.metadata.tokens === BILLING_PRO_TOKENS_AMOUNT,
          packageButtonLocale:
            Number(price?.unit_amount_decimal) > activePrice ? 'billing.other.upgradePlan' : 'billing.other.changePlan',
          isDisabledPlanSwitch: period === PERIOD.month && price?.recurring.interval === PERIOD.month,
        };
      }) || [];

    const hasActivePackage = packageConfig.some(pkg => pkg.isActive);

    const sortedPackages = hasActivePackage
      ? packageConfig.sort((a, b) => a.tokensInPackage - b.tokensInPackage)
      : [freePackage, ...packageConfig].sort((a, b) => a.tokensInPackage - b.tokensInPackage);

    if (me?.subscriptions?.[0]?.stripe_customer_id && !hasActivePackage) {
      sortedPackages.shift();
    }

    if (!me?.subscriptions?.[0]?.subscription_id) {
      setActivePrice(0);
    }

    (sortedPackages as unknown as SortedPackage[]).forEach((pkg, index: number) => {
      if (!pkg.isFree) {
        pkg.bgColor = colors[index % colors.length];
      }
    });

    return sortedPackages.filter(p => p.packagePriceTitle);
  }, [
    me?.subscriptions,
    packagesData,
    convertedPrices,
    subscriptionData,
    subscriptionError,
    isLoadingMutation,
    activePrice,
    period,
    handleCheckout,
    handleTopup,
    snackbar,
    t,
    handleCancelSubscription,
  ]);

  return {
    config,
    isLoadingQueries,
    packagesData,
    pricesData,
    subscriptionData,
    handleTopup,
    handleCheckout,
    isLoadingMutation,
  };
};
