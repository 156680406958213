import { DATAGRID_FOOTER_HEIGHT, LEADS_TABLE_ADJUSTED_HEIGHT } from '@constants/common';

import { Box, BoxProps, GridProps, IconButton, IconButtonProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledLeadsBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  borderLeft: `1px solid ${theme.colors.background.disabledButton}`,
  width: 'calc(100% - 250px)',
}));

export const StyledSmartHeightDataGridContainer = styled(Box)<
  GridProps & { headerAppHeight: number; expanded?: boolean }
>(({ headerAppHeight, expanded }) => ({
  height: `calc(100vh - ${headerAppHeight}px - ${LEADS_TABLE_ADJUSTED_HEIGHT}px - ${DATAGRID_FOOTER_HEIGHT}px)`,
  width: expanded ? 'calc(100% - 250px)' : '100%',
}));

export const StyledSmartHeightFiltersContainer = styled(Box)<GridProps & { headerAppHeight: number }>(
  ({ headerAppHeight }) => ({
    height: `calc(100vh - ${headerAppHeight}px - ${LEADS_TABLE_ADJUSTED_HEIGHT}px - ${DATAGRID_FOOTER_HEIGHT}px)`,
    overflowY: 'auto',
  }),
);

export const StyledIconButton = styled(IconButton)<IconButtonProps>({
  '&.MuiIconButton-root:hover': {
    backgroundColor: 'transparent',
  },
});
